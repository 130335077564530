import { Pagination } from '@tcl-boron-prefabs/pagination';
import { ALL_SITES, OperationalFlagDisplayName, OperationalFlags, TimeProgramSiteStatus } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUS_CATEGORIES } from '@tempus/t-shared/src/constants/patient-tracker';
import { DEFAULT_TABLE_PAGE_SIZE, Spinner } from '@tempus/t-shared/ui';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';

import FiltersSidebar from '~/components/FiltersSidebar';
import PatientTrackerCard, { CardColumn } from '~/components/PatientTrackerCard';
import { UpdateOverlay } from '~/components/UpdateOverlay/UpdateOverlay';
import { RootState } from '~/store';
import { creators as patientTrackerCreators } from '~/store/patientTrackerV2';
import { SORT_OPTION } from '~/store/patientTrackerV2/constants';
import {
  getPatientTrackerRecordsRequestOptions,
  openTrialMaterials,
  trialStatusToDisplay,
} from '~/store/patientTrackerV2/helpers';
import { defaultFilters } from '~/store/patientTrackerV2/reducer';
import {
  PatientTrackerFilterField,
  PatientTrackerSingleDropdownFilters,
  PatientTrackerMultiDropdownFilters,
  PatientRecordMetadata,
  PatientTrackingDetailsWithPatientMeta,
  TimePatient,
} from '~/store/patientTrackerV2/types';
import { creators as trialCreators } from '~/store/trial';
import { Trial } from '~/store/trial/types';
import { useScrollLock } from '~/utils/misc';
import { ArrayParam } from '~/utils/query-params';

import MatchStatus from '../PatientTrackerCard/MatchStatus';
import PatientTrackerStatusTabGroup from '../PatientTrackerStatusTabGroup';
import NoPatients from './NoPatients';
import { useStyles } from './styles';

const orderedStatusCategories = [
  PATIENT_TRACKER_STATUS_CATEGORIES.PRIORITY,
  PATIENT_TRACKER_STATUS_CATEGORIES.NEW,
  PATIENT_TRACKER_STATUS_CATEGORIES.MONITORING,
  PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED,
  PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE,
  PATIENT_TRACKER_STATUS_CATEGORIES.SCREENING_QUEUE,
];

interface PatientTrackerProps {
  stateId: string;
  siteId: string;
  patientFullName?: string;
  trialId?: string;
  header?: ReactElement;
  visibleFilters?: PatientTrackerFilterField[];
  timePatients: TimePatient[];
  patientsMetadata: PatientRecordMetadata;
  hiddenTabs?: PATIENT_TRACKER_STATUS_CATEGORIES[];
}

const PatientTracker: React.FC<PatientTrackerProps> = ({
  stateId,
  siteId,
  header,
  visibleFilters,
  timePatients,
  patientsMetadata,
  hiddenTabs,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [initialize, setInitialize] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams(
    {
      stateId: StringParam,
      statusCategory: StringParam,
      pageSize: NumberParam,
      page: NumberParam,
      ptdId: StringParam,
      timePatientId: StringParam,
      [PatientTrackerFilterField.SORT_ORDER]: StringParam,
      [PatientTrackerFilterField.TRIAL_NAME]: StringParam,
      [PatientTrackerFilterField.TRIAL_TYPE]: StringParam,
      [PatientTrackerFilterField.TRIAL_STATUS]: ArrayParam,
      [PatientTrackerFilterField.PHYSICIAN]: StringParam,
      [PatientTrackerFilterField.INDICATIONS]: ArrayParam,
      [PatientTrackerFilterField.BIOMARKER]: StringParam,
      [PatientTrackerFilterField.PATIENT_NAME]: StringParam,
      [PatientTrackerFilterField.NOTE]: StringParam,
      [PatientTrackerFilterField.MATCH_STATUS]: ArrayParam,
    },
    { includeAllParams: true },
  );

  // to prevent body from scrolling when patient overlay is open
  useScrollLock(queryParams.timePatientId);

  const { statusCategory, categoryCounts, filters } = useSelector(
    ({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId],
  );
  const fetchingRecords = useSelector(({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId].fetchingRecords);
  const allTrials: Trial[] = useSelector(({ trial }: RootState) => trial.allTrials);

  const unfilteredCategoryTabs = Object.keys(categoryCounts)
    .map((category) => {
      return {
        title: category,
        count: categoryCounts[category],
      };
    })
    .sort((a, b) => {
      return (
        orderedStatusCategories.indexOf(a.title as PATIENT_TRACKER_STATUS_CATEGORIES) -
        orderedStatusCategories.indexOf(b.title as PATIENT_TRACKER_STATUS_CATEGORIES)
      );
    });

  const visibleCategoryTabs = hiddenTabs?.length
    ? unfilteredCategoryTabs.filter((tab) => !hiddenTabs.includes(tab.title as PATIENT_TRACKER_STATUS_CATEGORIES))
    : unfilteredCategoryTabs;

  const selectedUserSite = useSelector((state: RootState) => state.site.selectedUserSite);
  const isAllSitesSelected = selectedUserSite?.name == ALL_SITES;
  const columns: CardColumn<PatientTrackingDetailsWithPatientMeta>[] = [
    {
      header: 'Trial',
      customBody: (trackingDetails) => (
        <>
          <div
            onClick={() => openTrialMaterials(trackingDetails.trial?.id, selectedUserSite)}
            className={classes.trialName}
            data-testid="column-trial">
            {trackingDetails.trial?.shortName || trackingDetails.trial?.title}
          </div>
        </>
      ),
      accessor: 'trial',
    },
    {
      header: 'Cohort',
      customBody: (trackingDetails) => {
        const { arm } = trackingDetails;
        const siteArm = arm?.siteArms[0]; // There should only be 1 site arm per arm/institution
        const isOnHold =
          arm?.operationalFlag &&
          !(
            arm?.operationalFlag === OperationalFlags.NON_ACTIVE_SITES &&
            arm?.siteArms?.[0]?.status === TimeProgramSiteStatus.ACTIVE_TRIAL_SITE
          );
        return (
          <>
            <div data-testid="column-cohort">{arm ? arm?.name : ''}</div>
            <div>
              <i>
                {isOnHold ? OperationalFlagDisplayName : trialStatusToDisplay(siteArm?.status as TimeProgramSiteStatus)}
              </i>
            </div>
          </>
        );
      },
      accessor: 'arm',
    },
    {
      header: 'Match status',
      customBody: (trackingDetails) => (
        <MatchStatus status={trackingDetails.status} data-testid="column-match-status" />
      ),
      accessor: 'status',
    },
    {
      header: '',
      customBody: () => <></>,
      accessor: 'id',
    },
  ];

  const paginationState = {
    page: patientsMetadata?.skip + 1,
    pageSize: { label: `${patientsMetadata?.numRecords} rows`, value: patientsMetadata?.numRecords.toString() },
  };
  const pageSize = Math.ceil(patientsMetadata?.totalRecords / patientsMetadata?.numRecords);

  const pageSizeOptions = [15, 30, 50, 100].map((pageSize) => ({
    label: `${pageSize} rows`,
    value: pageSize.toString(),
  }));

  const refreshRecords = () => {
    dispatch(
      patientTrackerCreators.getPatientTrackerCategoryCounts(
        stateId,
        selectedUserSite?.id || ALL_SITES,
        getPatientTrackerRecordsRequestOptions(statusCategory),
      ),
    );
    dispatch(
      patientTrackerCreators.getPatientTrackerRecords(
        stateId,
        selectedUserSite?.id || ALL_SITES,
        getPatientTrackerRecordsRequestOptions(statusCategory),
      ),
    );
  };

  useEffect(() => {
    refreshRecords();
  }, [selectedUserSite, patientsMetadata.numRecords, patientsMetadata.skip]);

  useEffect(() => {
    dispatch(trialCreators.getAllTrials());
  }, [selectedUserSite]);

  const updatePagination = (nextState) => {
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        parseInt(nextState.pageSize.value),
        0,
        patientsMetadata?.totalRecords,
      ),
    );
  };

  const incrementPage = () => {
    if (patientsMetadata?.skip + 1 === pageSize) {
      return;
    }
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        patientsMetadata.numRecords,
        patientsMetadata?.skip + 1,
        patientsMetadata?.totalRecords,
      ),
    );
  };
  const decrementPage = () => {
    if (patientsMetadata.skip === 0) {
      return;
    }
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        patientsMetadata.numRecords,
        patientsMetadata?.skip - 1,
        patientsMetadata?.totalRecords,
      ),
    );
  };

  useEffect(() => {
    if (!initialize) {
      return;
    }
    dispatch(
      patientTrackerCreators.setPatientTrackerPagination(
        stateId,
        patientsMetadata?.numRecords,
        0,
        patientsMetadata?.totalRecords,
      ),
    );
    refreshRecords();
  }, [statusCategory, initialize]);

  // update the query params when the filters or statusCategory change
  useEffect(() => {
    const filtersToSave = Object.keys(filters).reduce((acc, key) => {
      if (!filters[key]) {
        acc[key] = null;
      } else if (PatientTrackerSingleDropdownFilters.includes(key as PatientTrackerFilterField)) {
        acc[key] = filters[key].value;
      } else if (PatientTrackerMultiDropdownFilters.includes(key as PatientTrackerFilterField)) {
        // deal with the multi dropdown filters
        acc[key] = filters[key].map((filter) => filter.value);
      } else {
        acc[key] = filters[key];
      }
      return acc;
    }, {});

    setQueryParams({
      stateId,
      statusCategory: decodeURIComponent(statusCategory),
      // expand the filters to save
      ...filtersToSave,
    });
  }, [statusCategory, filters]);

  // update the query params when the pagination changes
  useEffect(() => {
    setQueryParams({
      page: paginationState.page - 1,
      pageSize: patientsMetadata?.numRecords,
    });
  }, [paginationState]);

  // load the initial url state from the query params
  useEffect(() => {
    if (queryParams.stateId === stateId) {
      // load the pagination state from the query params
      dispatch(
        patientTrackerCreators.setPatientTrackerPagination(
          stateId,
          queryParams.pageSize || DEFAULT_TABLE_PAGE_SIZE,
          queryParams.page || 0,
          0,
        ),
      );

      if (queryParams.statusCategory) {
        dispatch(
          patientTrackerCreators.setPatientTrackerStatusCategory(
            stateId,
            decodeURIComponent(queryParams.statusCategory) as PATIENT_TRACKER_STATUS_CATEGORIES,
          ),
        );
      }

      // parse the filters from the query params
      const filters = {};
      Object.values(PatientTrackerFilterField).forEach((field) => {
        if (queryParams[field]) {
          if (PatientTrackerSingleDropdownFilters.includes(field as PatientTrackerFilterField)) {
            if (field === PatientTrackerFilterField.TRIAL_NAME) {
              const trial = allTrials.find((trial) => trial.id === queryParams[field]);
              filters[field] = { label: trial?.shortName || trial?.title, value: queryParams[field] };
            } else if (field === PatientTrackerFilterField.SORT_ORDER) {
              filters[field] = { label: SORT_OPTION[queryParams[field] as string], value: queryParams[field] };
            } else {
              filters[field] = { label: _.capitalize(queryParams[field] as string), value: queryParams[field] };
            }
          } else if (PatientTrackerMultiDropdownFilters.includes(field as PatientTrackerFilterField)) {
            filters[field] = (queryParams[field] as string[])
              .filter(Boolean)
              .map((value) => ({ label: _.capitalize(value), value }));
          } else {
            filters[field] = queryParams[field];
          }
        } else {
          filters[field] = defaultFilters[field];
        }
      });

      if (Object.keys(filters).length) {
        dispatch(patientTrackerCreators.setPatientTrackerFilters(stateId, filters));
      }
    }
  }, []);

  useEffect(() => {
    if (fetchingRecords && !initialize) {
      setInitialize(true);
    }
  }, [fetchingRecords]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.twoColumnContainer}>
        <div className={classes.sidebar}>
          <FiltersSidebar
            stateId={stateId}
            siteId={siteId}
            visibleFilters={visibleFilters}
            patientsMetadata={patientsMetadata}
          />
        </div>
        <div className={classes.mainSection}>
          {header}
          <div>
            <PatientTrackerStatusTabGroup
              stateId={stateId}
              siteId={siteId}
              tabs={visibleCategoryTabs}
              value={statusCategory}
              onChange={(category) =>
                dispatch(
                  patientTrackerCreators.setPatientTrackerStatusCategory(
                    stateId,
                    category as PATIENT_TRACKER_STATUS_CATEGORIES,
                  ),
                )
              }
            />
            {/* {categoryCounts[statusCategory] !== null && TODO update after status count endpoint is ready */}
            {fetchingRecords && <Spinner loading={Boolean(fetchingRecords)} transparent relativePosition />}
            {!fetchingRecords &&
              Boolean(timePatients.length) &&
              timePatients.map((timePatient) => (
                <PatientTrackerCard
                  title={`${timePatient.patient.firstName} ${timePatient.patient.lastName}`}
                  status={timePatient?.status || ''}
                  columns={columns}
                  timePatient={timePatient}
                  key={timePatient.id!}
                  stateId={stateId}
                  siteId={siteId}
                  showSiteName={isAllSitesSelected}
                />
              ))}
            {/* {categoryCounts[statusCategory] === 0 && <NoPatients />} TODO update after status count endpoint is ready */}
            {!fetchingRecords && !timePatients.length && <NoPatients />}
          </div>
          {!fetchingRecords && Boolean(timePatients.length) && (
            <Pagination
              className={classes.pagination}
              goToPreviousPage={decrementPage}
              goToNextPage={incrementPage}
              onChange={(nextState) => updatePagination(nextState)}
              totalPages={pageSize}
              value={paginationState}
              pageSizeOptions={pageSizeOptions}
            />
          )}
          <UpdateOverlay siteId={siteId} stateId={stateId} />
        </div>
      </div>
    </div>
  );
};

export default PatientTracker;
