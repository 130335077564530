import {
  PATIENT_TRACKER_STATUS_CATEGORIES,
  PATIENT_TRACKER_STATUS_ROLLUP,
} from '@tempus/t-shared/src/constants/patient-tracker';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { creators } from '~/store/patientTrackerV2';
import { PatientTrackerStateId, TimePatient } from '~/store/patientTrackerV2/types';

import InactiveMatchesGroup from './InactiveMatchesGroup.tsx';
import useStyles from './InactiveMatchesTab.styles';

interface InactiveMatchesTabProps {
  patient: TimePatient;
}

export default ({ patient }: InactiveMatchesTabProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [groupedMatches, setGroupedMatches] = React.useState({});

  useEffect(() => {
    const matchesToDisplay = patient.patientTrackingDetails.filter(
      (ptd) =>
        [
          ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE],
          ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED],
        ].includes(ptd.status) && !ptd.editing,
    );

    const updatedGroupedMatches = {};

    for (const match of matchesToDisplay) {
      if (match.status in updatedGroupedMatches) {
        updatedGroupedMatches[match.status].push(match);
      } else {
        updatedGroupedMatches[match.status] = [match];
      }
    }

    setGroupedMatches(updatedGroupedMatches);
  }, [patient.patientTrackingDetails]);

  const editMatch = (matchId: string) => {
    const timePatientWithEditingFlag = {
      ...patient,
      patientTrackingDetails: [
        ...patient.patientTrackingDetails.filter((ptd) => ptd.id !== matchId),
        ...patient.patientTrackingDetails.filter((ptd) => ptd.id === matchId).map((ptd) => ({ ...ptd, editing: true })),
      ],
    };

    dispatch(creators.setTimePatientData(PatientTrackerStateId.PATIENT_TRACKER_V2, timePatientWithEditingFlag));
  };

  return (
    <div className={classes.container}>
      {Object.keys(groupedMatches).map((status) => (
        <InactiveMatchesGroup key={status} matches={groupedMatches[status]} editMatch={editMatch} />
      ))}
    </div>
  );
};
