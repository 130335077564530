import { ErrorPalette, WarningPalette } from '@tcl-boron-colors/colors';
import { Button } from '@tcl-boron-prefabs/button';
import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import {
  PATIENT_TRACKER_STATUS_CATEGORIES,
  PATIENT_TRACKER_STATUS_ROLLUP,
  PATIENT_TRACKER_STATUSES,
} from '@tempus/t-shared/src/constants/patient-tracker';
import { storeActions } from '@tempus/t-shared/ui';
import cn from 'classnames';
import { every, get, includes, isEmpty, omit, some } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Warning, Fail, Close } from 'tcl-v3/icons';
import { DropdownOption } from 'tcl-v3/models';

import { RootState } from '~/store';
import api from '~/store/api';
import { TrialMatchesUpdates, UpdateTimePatientV2 } from '~/store/api/types';
import { creators as patientTrackerCreators } from '~/store/patientTrackerV2';
import {
  getPatientStatusDropdownOptions,
  getVisitTypeDropdownOptions,
  PATIENT_OVERLAY_TAB_ID,
} from '~/store/patientTrackerV2/constants';
import { getPatientTrackerRecordsRequestOptions, isDatePast } from '~/store/patientTrackerV2/helpers';
import { TimePatient } from '~/store/patientTrackerV2/types';
import { formatDateForDisplay } from '~/utils/misc';

import { NoActiveTrialMatchesCard } from './NoActiveTrialMatchesCard';
import RightPane from './RightPane';
import { TrialMatchCard } from './TrialMatchCard';
import useStyles from './UpdateOverlayContent.styles';
import VisitDatePastBanner from './VisitDatePastBanner';
import { getChangedValues, PickerStatus } from './utils';

export interface TimePatientValues {
  status: string;
  visitType: string;
  visitDate: string;
  note: string;
  responseRequired: boolean;
  physicianId?: string;
}
interface UpdateOverlayContentProps {
  timePatient: TimePatient;
  stateId: string;
  siteId: string;
  onClose: () => void;
  onDataRefresh?: () => void;
  isOpen: boolean;
}

export const UpdateOverlayContent: React.FC<UpdateOverlayContentProps> = ({
  timePatient,
  stateId,
  siteId,
  onClose = () => {},
  onDataRefresh,
  isOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matchDetailsContainerRef = useRef<HTMLDivElement | null>(null);
  const statusCategory = useSelector(({ patientTrackerV2 }: RootState) => patientTrackerV2[stateId].statusCategory);
  const isInternalUser = useSelector(
    (state: RootState) => state.user.canViewAllPatientTracking && state.user.canWritePatientTracking,
  );
  const { patient, patientTrackingDetails: trackingDetails, physician } = timePatient;

  const initialTimePatientValues: TimePatientValues = {
    status: timePatient?.status || '',
    visitType: timePatient?.timePatientVisits[0]?.visitType || '',
    visitDate: timePatient?.timePatientVisits[0]?.visitDate
      ? moment(timePatient?.timePatientVisits[0]?.visitDate).format('MM/DD/YYYY')
      : '',
    note: '', // Note is always initialized as an empty textbox
    responseRequired: timePatient?.responseRequired,
    physicianId: timePatient?.physician?.id || undefined,
  };

  const [note, setNote] = useState('');
  const [responseRequired, setResponseRequired] = useState(timePatient.responseRequired);
  const [physicianId, setPhysicianId] = useState(timePatient?.physician?.id);

  const [isUpdating, setIsUpdating] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const [selectedPatientStatus, setSelectedPatientStatus] = useState<DropdownOption | null>({
    label: initialTimePatientValues.status,
    value: initialTimePatientValues.status,
  });
  const updateResponseRequired = (newResponseRequired: boolean) => {
    setResponseRequired(newResponseRequired);
  };
  const updateNote = (newNote: string) => {
    setNote(newNote);
  };
  const [selectedVisitType, setSelectedVisitType] = useState<DropdownOption | null>({
    label: initialTimePatientValues.visitType,
    value: initialTimePatientValues.visitType,
  });

  useEffect(() => {
    if (!selectedVisitType) {
      setSelectedDate({ dateString: '' });
    }
  }, [selectedVisitType]);

  const [selectedDate, setSelectedDate] = useState<{ dateString: string }>({
    dateString: initialTimePatientValues.visitDate,
  });
  const [isThereUnsavedNotes, setIsThereUnsavedNotes] = useState(false);
  const [trialMatchChanges, setTrialMatchChanges] = useState<{ [key: string]: Omit<TrialMatchesUpdates, 'id'> }>({});

  const getDatePickerStatus = (date: string): PickerStatus => {
    if (!date && selectedVisitType?.value) {
      return PickerStatus.ERROR;
    }
    if (isDatePast(date) || (!date && !selectedVisitType?.value)) {
      return PickerStatus.WARNING;
    }

    return PickerStatus.DEFAULT;
  };
  const getTypePickerStatus = (type: string): PickerStatus => {
    if (selectedDate.dateString && !type) {
      return PickerStatus.ERROR;
    }
    if (!selectedDate.dateString && !type) {
      return PickerStatus.WARNING;
    }

    return PickerStatus.DEFAULT;
  };
  const [typePickerStatus, setTypePickerStatus] = useState<PickerStatus>(
    getTypePickerStatus(selectedVisitType?.value as string),
  );
  const [datePickerStatus, setDatePickerStatus] = useState<PickerStatus>(getDatePickerStatus(selectedDate.dateString));

  const [enrolledTrialsLength, setEnrolledTrialsLength] = useState(0);
  const [rightPaneTab, setRightPaneTab] = useState<PATIENT_OVERLAY_TAB_ID>(PATIENT_OVERLAY_TAB_ID.ACTIVITIES);

  const refreshData = () => {
    dispatch(
      patientTrackerCreators.getPatientTrackerRecords(
        stateId,
        siteId,
        getPatientTrackerRecordsRequestOptions(statusCategory),
      ),
    );
    dispatch(patientTrackerCreators.getPatientTrackerCategoryCounts(stateId, siteId));
    onDataRefresh?.();
  };

  const handleTrialMatchesChanges = (ptdId, changes) => {
    if (isEmpty(changes)) {
      setTrialMatchChanges((prevState) => {
        const newState = omit(prevState, ptdId);

        return newState;
      });
      return;
    }
    setTrialMatchChanges({ ...trialMatchChanges, ...{ [ptdId]: changes } });
  };

  const scrollToBottom = () => {
    const container = matchDetailsContainerRef.current;

    if (container) {
      const scroll = container.scrollHeight - container.clientHeight;
      container.scrollTo({ top: scroll, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    Object.keys(trialMatchChanges).forEach((trialId) => {
      if (!trackingDetails.some((ptd) => ptd.trial.id === trialId)) {
        delete trialMatchChanges[trialId];
      }
    });

    if (some(trackingDetails, (ptd) => ptd.editing)) {
      scrollToBottom();
    }

    setEnrolledTrialsLength(trackingDetails.filter((ptd) => ptd.status === PATIENT_TRACKER_STATUSES.ENROLLED).length);
  }, [trackingDetails]);

  const getTimePatientChanges = (): Pick<
    UpdateTimePatientV2,
    'status' | 'visitDate' | 'visitType' | 'note' | 'responseRequired' | 'physicianId'
  > => {
    const changes = getChangedValues(initialTimePatientValues, {
      status: selectedPatientStatus?.value,
      visitType: selectedVisitType?.value || '',
      visitDate: selectedDate.dateString ? moment(selectedDate.dateString).format('MM/DD/YYYY') : '',
      note: note,
      responseRequired: responseRequired,
      physicianId: physicianId,
    });

    return changes;
  };

  const createRequestBody = () => {
    const changes = getTimePatientChanges();
    const updates: UpdateTimePatientV2 = {};

    if ('visitType' in changes || 'visitDate' in changes) {
      updates.visitDate = selectedDate.dateString ? moment(selectedDate.dateString).format('YYYY-MM-DD') : null;
      updates.visitType = selectedVisitType ? selectedVisitType.value : null;
    }

    if ('status' in changes) {
      updates.status = changes.status;
    }

    if (!isEmpty(trialMatchChanges)) {
      updates.trialMatches = Object.keys(trialMatchChanges).map((id) => {
        const ptdUpdates = trialMatchChanges[id];
        return {
          id,
          ...ptdUpdates,
        };
      });
    }

    if ('note' in changes) {
      updates.note = changes.note;
      updates.patientLinkageId = timePatient.patientLinkageId;
      updates.institutionId = timePatient.institution.id;
    }

    if ('responseRequired' in changes) {
      updates.responseRequired = changes.responseRequired;
    }

    if ('physicianId' in changes) {
      updates.physicianId = changes.physicianId;
    }

    return updates;
  };

  const areRequiredFieldsMissing = (updates: UpdateTimePatientV2) => {
    if (!updates.trialMatches?.length) {
      return false;
    }

    return updates.trialMatches.some((match) => {
      if (match.status === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE && !match.reasonNotAMatch) {
        dispatch(storeActions.notification.showErrorMessage('Missing required fields: reason not a match'));
        return true;
      }

      if (match.status === PATIENT_TRACKER_STATUSES.CONSENTED) {
        const isFieldMissing = (field: 'patientConsentedDate' | 'dateConsentRecognizedByTempus') =>
          match[field] === null || (!match[field] && trackingDetails.some((ptd) => match.id === ptd.id && !ptd[field]));

        if (
          isFieldMissing('patientConsentedDate') ||
          (isFieldMissing('dateConsentRecognizedByTempus') && isInternalUser)
        ) {
          dispatch(
            storeActions.notification.showErrorMessage(
              'Missing required fields: patient consented date or date consent recognized by Tempus',
            ),
          );
          return true;
        }
      }

      return false;
    });
  };

  const updatePatientTrackerRecord = async () => {
    setIsUpdating(true);

    if (
      (selectedVisitType?.value && !selectedDate.dateString) ||
      (!selectedVisitType?.value && selectedDate.dateString)
    ) {
      dispatch(
        storeActions.notification.showErrorMessage('Missing required fields: next visit type or next visit date'),
      );
      setIsUpdating(false);
      return;
    }

    try {
      const updates = createRequestBody();

      if (areRequiredFieldsMissing(updates)) {
        setIsUpdating(false);
        return;
      }
      await api.timePatient.updateTimePatientV2(timePatient.id, updates);
      dispatch(storeActions.notification.showSuccessMessage('Match updated'));
      refreshData();
    } catch (error) {
      dispatch(storeActions.notification.showErrorMessage('Failed to update patient tracker record.'));
    } finally {
      setNote('');
      setIsThereUnsavedNotes(false);
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    setDatePickerStatus(getDatePickerStatus(selectedDate.dateString));
    setTypePickerStatus(getTypePickerStatus(selectedVisitType?.value as string));
  }, [selectedDate, selectedVisitType]);

  useEffect(() => {
    // on refetching data the whole overlay component gets re-rendered causing the actual overlay to
    // close and the 'update' button to show up, this should re-fetch data when the TimePatient gets updated
    // only after we close the overlay manually.
    if (!isOpen && shouldFetchData) {
      refreshData();
    }
  }, [shouldFetchData, isOpen]);

  const handleClose = () => {
    if (!isEmpty(getTimePatientChanges()) || !isEmpty(trialMatchChanges) || isThereUnsavedNotes) {
      const userConfirmed = window.confirm('You have unsaved changes on this page. Unsaved changes will be discarded.');
      if (userConfirmed) {
        onClose();
      }
      return;
    }
    onClose();
  };

  const OverrideCloseButton = () => (
    <div className={classes.closeButton} onClick={handleClose}>
      <Close />
    </div>
  );

  return (
    <>
      <div className={classes.updateOverlayContainer}>
        <div className={classes.paneContainer}>
          <div className={classes.updateOverlayWrapper}>
            {/* 
        Need to create an override to the normal overlay close button 
        as that one does not allow us to cancel a close which is required 
        if the user has not saved 
      */}
            <OverrideCloseButton />
            <div className={classes.patientDetailsHeader}>
              <div className={classes.updateOverlayHeader}>
                {patient.firstName} {patient.lastName}
              </div>
              <div className={classes.updateOverlayTwoColumnContainer}>
                <div>
                  <div className={classes.updateOverlaySubHeader}>
                    <span data-testid="update-overlay-DOB">{formatDateForDisplay(patient.dateOfBirth, true)}</span>
                  </div>
                  <div data-testid="update-overlay-indication">{trackingDetails[0]?.patientCancerType}</div>
                </div>
                <div data-testid="update-overlay-Dr.">
                  <div>
                    {physician?.name ? (
                      `Dr. ${physician.name}`
                    ) : (
                      <span className={classes.physicianUnknown}>Physician unknown</span>
                    )}
                  </div>
                  <div>{timePatient.institution.shortName || timePatient.institution.name}</div>
                </div>
              </div>
            </div>
            <div ref={matchDetailsContainerRef} className={classes.mainBody}>
              <VisitDatePastBanner nextVisit={get(timePatient, 'timePatientVisits[0]')} />
              <div className={classes.patientStatusContainer}>
                <SingleSelectDropdown
                  onChange={(patientStatus) => setSelectedPatientStatus(patientStatus)}
                  value={selectedPatientStatus}
                  label="Patient status"
                  data-testid="update-overlay-patient-status"
                  options={getPatientStatusDropdownOptions()}
                  data-pendo-id="update_pt-patient_status"
                />
              </div>
              <div className={cn(classes.updateOverlayTwoColumnContainer, classes.visitDetailsContainer)}>
                <div>
                  <SingleSelectDropdown
                    onChange={(visitType) => setSelectedVisitType(visitType)}
                    value={selectedVisitType}
                    label="Next visit type"
                    data-testid="update-overlay-next-visit-type"
                    options={getVisitTypeDropdownOptions()}
                    status={typePickerStatus}
                    clearable
                    data-pendo-id="update_pt-visit_type"
                  />
                  {typePickerStatus === PickerStatus.WARNING && (
                    <div className={classes.dateWarning}>
                      <div className={classes.warningIcon}>
                        <Warning width={12} height={12} color={WarningPalette[700]} />
                      </div>
                      Type is missing
                    </div>
                  )}
                  {typePickerStatus === PickerStatus.ERROR && (
                    <div className={classes.dateError}>
                      <div className={classes.errorIcon}>
                        <Fail width={12} height={12} color={ErrorPalette[700]} />
                      </div>
                      Visits must have a type
                    </div>
                  )}
                </div>

                <div>
                  <SingleDatePicker
                    className={classes.date}
                    label="Next visit date"
                    data-testid="update-overlay-next-visit-date"
                    onChange={(date) => setSelectedDate({ dateString: date.dateString })}
                    value={selectedDate}
                    status={datePickerStatus}
                    data-pendo-id="update_pt-visit_date"
                  />
                  {datePickerStatus === PickerStatus.WARNING && (
                    <div className={classes.dateWarning}>
                      <div className={classes.warningIcon}>
                        <Warning width={12} height={12} color={WarningPalette[700]} />
                      </div>
                      {!Boolean(selectedDate?.dateString) ? 'Date is missing' : 'Date is in the past'}
                    </div>
                  )}
                  {datePickerStatus === PickerStatus.ERROR && (
                    <div className={classes.dateError}>
                      <div className={classes.errorIcon}>
                        <Fail width={12} height={12} color={ErrorPalette[700]} />
                      </div>
                      Visits must have a date
                    </div>
                  )}
                </div>
              </div>
              {every(
                trackingDetails,
                (ptd) =>
                  includes(
                    [
                      ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE],
                      ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED],
                    ],
                    ptd.status,
                  ) && !ptd.editing,
              ) ? (
                <NoActiveTrialMatchesCard
                  showInactiveMatches={() => {
                    setRightPaneTab(PATIENT_OVERLAY_TAB_ID.INACTIVE_MATCHES);
                  }}
                />
              ) : (
                trackingDetails
                  .filter(
                    (ptd) =>
                      !includes(
                        [
                          ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE],
                          ...PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED],
                        ],
                        ptd.status,
                      ) || ptd.editing,
                  )
                  .map((detail) => (
                    <TrialMatchCard
                      key={detail.id}
                      ptd={detail}
                      handleTrialMatchesChanges={handleTrialMatchesChanges}
                    />
                  ))
              )}
              {Boolean(enrolledTrialsLength) && (
                <div
                  className={classes.enrolledTrialsLink}
                  onClick={() =>
                    setRightPaneTab(PATIENT_OVERLAY_TAB_ID.INACTIVE_MATCHES)
                  }>{`Patient has enrolled in (${enrolledTrialsLength}) trial${
                  enrolledTrialsLength > 1 ? 's' : ''
                }`}</div>
              )}
            </div>
          </div>
          <div className={classes.updateOverlayWrapper}>
            <RightPane
              stateId={stateId}
              timePatient={timePatient}
              setShouldFetchData={setShouldFetchData}
              setIsThereUnsavedNotes={setIsThereUnsavedNotes}
              refreshData={refreshData}
              currentTab={rightPaneTab}
              setCurrentTab={setRightPaneTab}
              setNote={updateNote}
              note={note}
              responseRequired={responseRequired}
              setResponseRequired={updateResponseRequired}
              updatePhysician={setPhysicianId}
            />
          </div>
        </div>
        <div className={classes.bottomButtons}>
          <Button
            small
            buttonType="secondary"
            onClick={() => handleClose()}
            ariaLabel="Close"
            data-testid="update-overlay-close-button"
            data-pendo-id="update_pt-close">
            Close
          </Button>
          <Button
            small
            buttonType="primary"
            loading={isUpdating}
            data-testid="update-overlay-save-button"
            onClick={updatePatientTrackerRecord}
            ariaLabel="Save"
            data-pendo-id="update_pt-save">
            Save changes
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpdateOverlayContent;
