import { SagaIterator } from '@redux-saga/core';
import { PATIENT_TRACKER_STATUS_ROLLUP } from '@tempus/t-shared/src/constants/patient-tracker';
import { storeActions, IMPERSONATION_ERROR_CODE } from '@tempus/t-shared/ui';
import _ from 'lodash';
import { call, put, takeLatest, takeLeading, select, all } from 'redux-saga/effects';

import api from '~/store/api';
import { getCurrentDate } from '~/utils/misc';

import { creators } from './actions';
import { getPatientTrackerRecordsRequestOptions, parseFilters } from './helpers';
import {
  GET_PATIENT_TRACKER_V2_RECORDS,
  GET_PATIENT_TRACKER_V2_RECORDS_CSV,
  GET_PATIENT_TRACKER_V2_RECORD,
  GetPatientTrackerRecords,
  GetPatientTrackerRecord,
  GetPatientTrackerRecordsCsv,
  GET_PATIENT_TRACKER_V2_RECORDS_FAILED,
  GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS,
  GetPatientCategoryCounts,
  SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE,
} from './types';

function* getPatientTrackerRecords({ id, siteId, opts = {} }: GetPatientTrackerRecords): SagaIterator {
  try {
    const { statusCategory, filters, patientsMetadata } = yield select(({ patientTrackerV2 }) => patientTrackerV2[id]);
    const matchStatuses = PATIENT_TRACKER_STATUS_ROLLUP[statusCategory];
    const matchStatusFilter = (filters.matchStatus || []).map((opt) => opt.value);
    const combinedMatchStatus = matchStatusFilter.length
      ? _.intersection(matchStatuses, matchStatusFilter)
      : matchStatuses;

    const { orderBy, filterParams } = parseFilters(filters);

    const { patients, patientCount } = yield call(api.patientTrackerV2.getPatientRecords, {
      institutionId: siteId,
      matchStatus: combinedMatchStatus,
      orderBy,
      ...filterParams,
      ...opts,
      page: patientsMetadata.skip,
      pageSize: patientsMetadata.numRecords,
    });

    yield put(creators.setPatientTrackerRecords(id, patients));
    yield put(
      creators.setPatientTrackerPagination(id, patientsMetadata.numRecords, patientsMetadata.skip, patientCount),
    );
  } catch (error) {
    yield put(creators.getPatientTrackerRecordsFailed(id, error));
  }
}

function* getPatientRecord({ id, timePatientId, siteId }: GetPatientTrackerRecord): SagaIterator {
  yield all([call(getTimePatientData, id, timePatientId, siteId), call(getInstitutionPhysicians, id, timePatientId)]);
}

function* getTimePatientData(stateId: string, timePatientId: string, siteId: string): SagaIterator {
  try {
    const timePatientData = yield call(api.patientTrackerV2.getPatientRecord, siteId, timePatientId);
    yield put(creators.setTimePatientData(stateId, timePatientData));
  } catch (error) {
    yield put(creators.getTimePatientDataFailed(stateId, error));
  }
}

function* getInstitutionPhysicians(stateId: string, timePatientId: string): SagaIterator {
  try {
    const physicians = yield call(api.timePatient.getInstitutionPhysicians, timePatientId);
    yield put(creators.setInstitutionPhysicians(stateId, physicians));
  } catch (error) {
    yield put(creators.getInstitutionPhysiciansFailed(stateId, error));
  }
}

function* getPatientRecordsCsv({ id, siteId, opts = {} }: GetPatientTrackerRecordsCsv): SagaIterator {
  try {
    const { statusCategory, filters } = yield select(({ patientTrackerV2 }) => patientTrackerV2[id]);
    const matchStatuses = PATIENT_TRACKER_STATUS_ROLLUP[statusCategory];
    const matchStatusFilter = (filters.matchStatus || []).map((opt) => opt.value);
    const combinedMatchStatus = matchStatusFilter.length
      ? _.intersection(matchStatuses, matchStatusFilter)
      : matchStatuses;
    const { orderBy, filterParams } = parseFilters(filters, opts.customFilterList);

    const data = yield call(api.patientTrackerV2.getPatientRecordsCsv, {
      institutionId: siteId,
      matchStatus: opts.includeAllCategories ? undefined : combinedMatchStatus,
      ...getPatientTrackerRecordsRequestOptions(statusCategory),
      orderBy,
      ...filterParams,
      ...opts,
    });

    const currentDate = getCurrentDate().replace(/\//g, '-');
    const fileName = `TIME_patient_tracker_${siteId}_${currentDate}_${Date.now()}.csv`;

    yield put(creators.setPatientTrackerRecordsCsv(id, fileName, data));
  } catch (error) {
    yield put(creators.getPatientTrackerRecordsCsvFailed(id, error));
  }
}

function* getPatientTrackerCategoryCounts({ id, siteId, opts = {} }: GetPatientCategoryCounts): SagaIterator {
  try {
    const filters = yield select(({ patientTrackerV2 }) => patientTrackerV2[id].filters);
    const { filterParams } = parseFilters(filters);
    const matchStatus =
      Array.isArray(filters.matchStatus) && filters.matchStatus.length
        ? filters.matchStatus.map((opt) => opt.value)
        : undefined;

    const categoryCounts = yield call(api.patientTrackerV2.getPatientTrackerCategoryCounts, {
      institutionId: siteId,
      matchStatus,
      ...filterParams,
      ...opts,
    });

    yield put(creators.setPatientTrackerCategoryCounts(id, categoryCounts));
  } catch (error) {
    yield put(creators.getPatientTrackerRecordsFailed(id, error));
  }
}

function* putErrorMessage(action): SagaIterator {
  const { error, defaultErrorMessage } = action;
  const { name, response, message } = error;
  const { statusText, data, status } = response || {};
  let errorText = message;

  if (name === IMPERSONATION_ERROR_CODE) {
    errorText = message;
  } else if (status === 409) {
    errorText = data.message;
  } else if (defaultErrorMessage) {
    errorText = defaultErrorMessage;
  } else if (statusText) {
    errorText = `${statusText}: ${data.message || message}`;
  }

  yield put(storeActions.notification.showErrorMessage(errorText));
}

export default [
  takeLeading(GET_PATIENT_TRACKER_V2_RECORDS, getPatientTrackerRecords),
  takeLeading(GET_PATIENT_TRACKER_V2_RECORD, getPatientRecord),
  takeLeading(GET_PATIENT_TRACKER_V2_RECORDS_CSV, getPatientRecordsCsv),
  takeLatest(GET_PATIENT_TRACKER_V2_RECORDS_FAILED, putErrorMessage),
  takeLatest(GET_PATIENT_TRACKER_V2_RECORDS_FAILED, putErrorMessage),
  takeLeading(GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS, getPatientTrackerCategoryCounts),
  takeLatest(SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE, putErrorMessage),
];
