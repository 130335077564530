import { BrandPalette, ErrorPalette } from '@tcl-boron-colors/colors';
import { Card } from '@tcl-boron-prefabs/card';
import { Collapse } from '@tcl-boron-prefabs/collapse';
import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { TextArea } from '@tcl-boron-prefabs/text-area';
import { TextInput } from '@tcl-boron-prefabs/text-input';
import { Toggle } from '@tcl-boron-prefabs/toggle';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { TimeProgramSiteStatus } from '@tempus/t-shared';
import {
  NO_LONGER_A_CANDIDATE_REASONS,
  PATIENT_TRACKER_STATUSES,
} from '@tempus/t-shared/src/constants/patient-tracker';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Information, LinkOut, Warning, Fail } from 'tcl-v3/icons';
import { DropdownOption } from 'tcl-v3/models';

import { RootState } from '~/store';
import { TrialMatchesUpdates } from '~/store/api/types';
import { getMatchStatusDropdownOptions } from '~/store/patientTrackerV2/constants';
import { openTrialMaterials, trialStatusToDisplay } from '~/store/patientTrackerV2/helpers';
import { PatientTrackingDetails } from '~/store/patientTrackerV2/types';
import { formatDateForDisplay } from '~/utils/misc';

import useStyles, { useCollapseStyles } from './TrialMatchCard.styles';
import { getChangedValues, validateDateByRules, dateRuleRequired, dateRuleNotInFuture, PickerStatus } from './utils';

interface TrialMatchCardProps {
  ptd: PatientTrackingDetails;
  handleTrialMatchesChanges: (ptdId: string, changes: Omit<TrialMatchesUpdates, 'id'>) => void;
}

export const TrialMatchCard: React.FC<TrialMatchCardProps> = ({ ptd, handleTrialMatchesChanges }) => {
  const classes = useStyles();
  const selectedUserSite = useSelector((state: RootState) => state.site.selectedUserSite);
  const isInternalUser = useSelector(
    (state: RootState) => state.user.canViewAllPatientTracking && state.user.canWritePatientTracking,
  );
  const [isMatchDetailsOpen, setIsMatchDetailsOpen] = useState(false);
  const [screeningNote, setScreeningNote] = useState<string>(ptd.tempusToPortalLatestPortalPatientUpdate || '');
  const [mutations, setMutations] = useState<string>(ptd.mutations || '');
  const [readyForNotificationDate, setReadyForNotificationDate] = useState(ptd.dateRnClearedForNotification);
  const [notificationSentDate, setNotificationSentDate] = useState(ptd.dateNotificationSentToSite);
  const [rescreen, setRescreen] = useState<boolean>(ptd.rescreen);
  const [noCredit, setNoCredit] = useState<boolean>(ptd.noCredit);
  const [enrollmentDate, setEnrollmentDate] = useState(ptd.firstTreatmentDate);

  const [internalRnToScreeningRnNote, setInternalRnToScreeningRnNote] = useState<string>(
    ptd.internalRnToScreeningRnNote || '',
  );

  const [selectedMatchStatus, setSelectedMatchStatus] = useState<DropdownOption | null>({
    label: ptd.status,
    value: ptd.status,
  });

  const [consentedDate, setConsentedDate] = useState(ptd.patientConsentedDate);
  const [consentRecognizedDate, setConsentRecognizedDate] = useState(ptd.dateConsentRecognizedByTempus);

  const [reasonNotAMatch, setReasonNotAMatch] = useState<DropdownOption | null>(
    ptd.reasonNotAMatch
      ? {
          label: ptd.reasonNotAMatch,
          value: ptd.reasonNotAMatch,
        }
      : null,
  );
  const [reasonNotAMatchDetails, setReasonNotAMatchDetails] = useState<string>(ptd.reasonNotAMatchDetails || '');

  function getInitialValues() {
    const keys = [
      'status',
      'reasonNotAMatch',
      'reasonNotAMatchDetails',
      'tempusToPortalLatestPortalPatientUpdate',
      'mutations',
      'rescreen',
      'noCredit',
      'dateRnClearedForNotification',
      'dateNotificationSentToSite',
      'patientConsentedDate',
      'dateConsentRecognizedByTempus',
      'firstTreatmentDate',
      'timeRnReviewCompletedDate',
      'screenedBy',
      'tAppNotes',
      'internalRnToScreeningRnNote',
    ] as const;

    type Keys = typeof keys[number];

    const initialValues: Record<Keys, string | DropdownOption | null> | object = {};
    keys.forEach((key) => {
      initialValues[key] = ptd[key] || ptd[key] === false ? ptd[key] : null;
    });

    return initialValues as Record<Keys, string | DropdownOption | null>;
  }
  const collapseStyles = useCollapseStyles();
  const [isOpen, setIsOpen] = React.useState(selectedMatchStatus?.value === PATIENT_TRACKER_STATUSES.INTERNAL_REVIEW);

  const timeRnReviewCompletedDate = ptd.timeRnReviewCompletedDate;
  const screenedBy = ptd.screenedBy;
  const tAppNotes = ptd.tAppNotes;

  const getFormattedDate = (date: string) =>
    date ? moment.utc(date, 'MM/DD/YYYY').format('YYYY-MM-DD HH:mm:ss.SSSSSS ZZ') : null;

  function updateSelectedMatchStatus(matchStatus: DropdownOption) {
    const {
      patientConsentedDate,
      dateConsentRecognizedByTempus,
      reasonNotAMatch,
      reasonNotAMatchDetails,
    } = getInitialValues();

    setSelectedMatchStatus(matchStatus);
    setConsentedDate((patientConsentedDate as string) || null);

    setConsentRecognizedDate((dateConsentRecognizedByTempus as string) || null);

    setReasonNotAMatch(reasonNotAMatch as DropdownOption);
    setReasonNotAMatchDetails(reasonNotAMatchDetails as string);
  }

  useEffect(() => {
    const currentValues = {
      status: selectedMatchStatus?.value || null,
      tempusToPortalLatestPortalPatientUpdate: screeningNote || null,
      mutations: mutations || null,
      dateRnClearedForNotification: readyForNotificationDate,
      dateNotificationSentToSite: notificationSentDate,
      rescreen,
      noCredit,
      patientConsentedDate: consentedDate,
      dateConsentRecognizedByTempus: consentRecognizedDate,
      reasonNotAMatch: reasonNotAMatch?.value || null,
      reasonNotAMatchDetails: reasonNotAMatchDetails || null,
      firstTreatmentDate: enrollmentDate || null,
      timeRnReviewCompletedDate: timeRnReviewCompletedDate || null,
      screenedBy: screenedBy || null,
      tAppNotes: tAppNotes || null,
      internalRnToScreeningRnNote: internalRnToScreeningRnNote || null,
    };
    const changedValues: Omit<TrialMatchesUpdates, 'id'> = getChangedValues(getInitialValues(), currentValues);
    if (changedValues.status && changedValues.status !== PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE) {
      changedValues.reasonNotAMatch = null;
      changedValues.reasonNotAMatchDetails = null;
    }
    handleTrialMatchesChanges(ptd.id, changedValues);
  }, [
    screeningNote,
    mutations,
    selectedMatchStatus,
    readyForNotificationDate,
    notificationSentDate,
    rescreen,
    noCredit,
    consentedDate,
    consentRecognizedDate,
    reasonNotAMatch,
    reasonNotAMatchDetails,
    enrollmentDate,
    timeRnReviewCompletedDate,
    screenedBy,
    tAppNotes,
    internalRnToScreeningRnNote,
  ]);
  const formattedDate = formatDateForDisplay(timeRnReviewCompletedDate, true);

  // validationsResults
  const consentedDateValidation = validateDateByRules(consentedDate, [dateRuleRequired, dateRuleNotInFuture]);
  const consentRecognizedDateValidation = validateDateByRules(consentRecognizedDate, [dateRuleRequired]);
  const enrolledDateValidation = validateDateByRules(enrollmentDate, [dateRuleRequired, dateRuleNotInFuture]);

  const renderPickerTooltip = (status?: PickerStatus, message?: string) => {
    const statusStyleMap = {
      [PickerStatus.WARNING]: {
        container: classes.warning,
        iconClass: classes.warningIcon,
        icon: <Warning width={12} height={12} color={ErrorPalette[700]} />,
      },
      [PickerStatus.ERROR]: {
        container: classes.dateError,
        iconClass: classes.errorIcon,
        icon: <Fail width={12} height={12} color={ErrorPalette[700]} />,
      },
    };

    if (status === PickerStatus.DEFAULT || !status) {
      return null;
    }

    const styling = statusStyleMap[status];

    return (
      <div className={styling.container}>
        <div className={styling.iconClass}>{styling.icon}</div>
        {message}
      </div>
    );
  };

  return (
    <Card className={classes.trialMatchCard}>
      <>
        <div className={classes.trialDetailsSection}>
          <div className={classes.header}>{ptd.trial?.shortName}</div>
          <div className={classes.twoColumns}>
            <div>{ptd.arm?.name}</div>
            <i>{trialStatusToDisplay(ptd.arm ? (ptd.arm?.siteArms[0]?.status as TimeProgramSiteStatus) : undefined)}</i>
          </div>
          <Card className={classes.matchDetailsCard}>
            <>
              {isMatchDetailsOpen ? (
                <>
                  <div className={classes.header}>About the match</div>
                  <p>
                    Updated{' '}
                    {ptd.tempusToPortalLatestPortalUpdateModifiedDate
                      ? moment(ptd.tempusToPortalLatestPortalUpdateModifiedDate)
                          .utc()
                          .format('MMM DD, YYYY')
                      : 'N/A'}
                  </p>

                  {isInternalUser ? (
                    <TextArea
                      textAreaType="secondary"
                      label="Screening note"
                      value={screeningNote}
                      onChange={setScreeningNote}
                      className={cn(classes.textArea)}
                    />
                  ) : (
                    <div>
                      <p>{screeningNote}</p>
                    </div>
                  )}
                  {isInternalUser && (
                    <TextArea
                      textAreaType="secondary"
                      label="Matching biomarkers"
                      value={mutations}
                      onChange={setMutations}
                      className={classes.textArea}
                    />
                  )}
                  <div className={classes.matchDetailsGrid}>
                    {!isInternalUser && (
                      <>
                        <div className={classes.matchLabel}>Matching biomarkers:</div>
                        <div className={classes.value}>{mutations ? mutations : 'N/A'}</div>
                      </>
                    )}
                    <div className={classes.matchLabel}>Phase:</div>
                    <div className={classes.value}>
                      {ptd.arm?.armSummary?.summary?.phases ? ptd.arm.armSummary.summary.phases.join(', ') : 'N/A'}
                    </div>

                    <div className={classes.matchLabel}>Agent:</div>
                    <div className={classes.value}>
                      {ptd.arm?.armSummary?.summary?.agents
                        ? ptd.arm.armSummary.summary.agents.map((agent) => agent.name).join(', ')
                        : 'N/A'}
                    </div>

                    <div className={classes.matchLabel}>Line of therapy:</div>
                    <div className={classes.value}>
                      {ptd.arm?.armSummary?.summary?.linesOfTherapies
                        ? ptd.arm.armSummary.summary.linesOfTherapies.join(', ')
                        : 'N/A'}
                    </div>
                  </div>
                  {isInternalUser && (
                    <>
                      <div className={classes.notificationDates}>
                        <SingleDatePicker
                          inputType="secondary"
                          label="Ready for notification"
                          data-testid="update-overlay-ready-for-notification-date"
                          onChange={(date) => setReadyForNotificationDate(getFormattedDate(date.dateString))}
                          value={{ dateString: moment.utc(readyForNotificationDate).format('MM/DD/YYYY') }}
                          data-pendo-id="update_pt-ready_for_notification_date"
                        />
                        <SingleDatePicker
                          inputType="secondary"
                          label="Notification sent"
                          data-testid="update-overlay-next-notification-sent-date"
                          onChange={(date) => setNotificationSentDate(getFormattedDate(date.dateString))}
                          value={{ dateString: moment.utc(notificationSentDate).format('MM/DD/YYYY') }}
                          data-pendo-id="update_pt-notification_sent_date"
                        />
                      </div>
                      <Toggle
                        label="Re-screen in T-app"
                        isSelected={rescreen}
                        labelPlacement="left"
                        onChange={() => setRescreen(!rescreen)}
                        hideCheckMark
                        className={classes.toggle}
                        data-testid="update-overlay-rescreen-toggle"
                      />
                    </>
                  )}

                  <div
                    className={classes.trialMaterials}
                    onClick={() => openTrialMaterials(ptd.trial.id, selectedUserSite)}
                    data-pendo-id="update_pt-view_trial_materials">
                    <LinkOut />
                    View trial materials
                  </div>

                  {isInternalUser && (
                    <div className={classes.collapseContainer}>
                      <Collapse
                        title="Tempus internal view only"
                        titlePlacement="right"
                        isOpen={isOpen}
                        onChange={() => setIsOpen(!isOpen)}
                        classes={collapseStyles}>
                        <>
                          <div className={classes.row}>
                            <div className={classes.leftColumn}>
                              <div className={classes.label}>Screening nurse</div>
                              <div>{screenedBy || 'Screening nurse unknown'}</div>
                            </div>
                            <div className={classes.rightColumn}>
                              <div className={classes.label}>Screening date</div>
                              <div>{formattedDate || 'Screening date unknown'}</div>
                            </div>
                          </div>
                          <div className={classes.noteRow}>
                            <div className={classes.label}>T-app screening note</div>
                            <div>{tAppNotes || 'No notes provided'}</div>
                          </div>
                          <TextArea
                            textAreaType="secondary"
                            label="Internal RN to Screening RN note"
                            value={internalRnToScreeningRnNote}
                            onChange={setInternalRnToScreeningRnNote}
                            className={cn(classes.textArea, classes.internalNote)}
                          />
                          <Toggle
                            label="No credit"
                            isSelected={noCredit}
                            labelPlacement="left"
                            onChange={() => setNoCredit(!noCredit)}
                            hideCheckMark
                            className={classes.toggle}
                            data-testid="update-overlay-no-credit-toggle"
                          />
                        </>
                      </Collapse>
                    </div>
                  )}
                </>
              ) : (
                <div className={classes.oneLineStatus}>{ptd.tempusToPortalLatestPortalPatientUpdate}</div>
              )}
              <div
                className={cn(typography.link)}
                onClick={() => setIsMatchDetailsOpen(!isMatchDetailsOpen)}
                data-pendo-id={`update_pt-${isMatchDetailsOpen ? 'hide' : 'view'}_match_details`}>
                {isMatchDetailsOpen ? '- Hide' : '+ View'} match details
              </div>
            </>
          </Card>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.matchStatusSection}>
          <div data-testid="match-status-title" className={classes.matchStatusTitle}>
            <b>Match status</b>
          </div>
          <div className={classes.matchStatusDropdownContainer}>
            <SingleSelectDropdown
              className={classes.statusDropdown}
              onChange={(status) => updateSelectedMatchStatus(status!)}
              value={selectedMatchStatus}
              label=""
              hideLabel
              data-testid="update-overlay-match-status"
              options={getMatchStatusDropdownOptions(isInternalUser)}
              escapeClippingArea
              data-pendo-id="update_pt-match_status"
            />
          </div>
          {selectedMatchStatus?.value === PATIENT_TRACKER_STATUSES.ENROLLED && (
            <>
              <SingleDatePicker
                label="Enrollment date"
                status={enrolledDateValidation.status}
                data-testid="update-overlay-enrollment-date"
                onChange={(date) => setEnrollmentDate(getFormattedDate(date.dateString))}
                value={{ dateString: moment.utc(enrollmentDate).format('MM/DD/YYYY') }}
                data-pendo-id="update_pt-enrollment-date"
              />
              {renderPickerTooltip(enrolledDateValidation.status, enrolledDateValidation.message)}
            </>
          )}
          {selectedMatchStatus?.value === PATIENT_TRACKER_STATUSES.CONSENTED && (
            <>
              <SingleDatePicker
                label="Consented date"
                status={consentedDateValidation.status}
                data-testid="update-overlay-consented-date"
                onChange={(date) => setConsentedDate(getFormattedDate(date.dateString))}
                value={{ dateString: moment.utc(consentedDate).format('MM/DD/YYYY') }}
                data-pendo-id="update_pt-consented_date"
              />
              {renderPickerTooltip(consentedDateValidation.status, consentedDateValidation.message)}
              {isInternalUser && (
                <>
                  <SingleDatePicker
                    label="Consent recognized date"
                    status={consentRecognizedDateValidation.status}
                    className={classes.consentRecognizedDate}
                    data-testid="update-overlay-consent-recognized-date"
                    onChange={(date) => setConsentRecognizedDate(getFormattedDate(date.dateString))}
                    value={{ dateString: moment.utc(consentRecognizedDate).format('MM/DD/YYYY') }}
                    data-pendo-id="update_pt-consent_recognized_date"
                  />
                  {renderPickerTooltip(consentRecognizedDateValidation.status, consentRecognizedDateValidation.message)}
                  <div className={classes.helperText}>
                    <div className={classes.warningIcon}>
                      <Information width={12} height={12} color={BrandPalette[700]} />
                    </div>
                    Visibility internal only
                  </div>
                </>
              )}
            </>
          )}
          {selectedMatchStatus?.value === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE && (
            <>
              <SingleSelectDropdown
                className={classes.reasonDropdown}
                onChange={(reason) => setReasonNotAMatch(reason)}
                value={reasonNotAMatch}
                label="Reason"
                data-testid="update-overlay-reason-not-a-match"
                data-pendo-id="update_pt-reason_not_a_match"
                options={Object.values(NO_LONGER_A_CANDIDATE_REASONS)
                  .sort()
                  .map((value) => ({ label: value, value }))}
                escapeClippingArea
                status={!reasonNotAMatch ? 'error' : 'default'}
              />
              {!reasonNotAMatch && renderPickerTooltip(PickerStatus.WARNING, 'Reason is required')}
              <TextInput
                label="Additional details"
                className={classes.additionalDetails}
                onChange={setReasonNotAMatchDetails}
                value={reasonNotAMatchDetails}
                inputType="primary"
                clearable
              />
            </>
          )}
        </div>
      </>
    </Card>
  );
};

export default TrialMatchCard;
