import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';
import { grayPalette } from 'tcl-v3/colors';

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
  },
  tabGroup: {
    marginTop: '32px',
  },
  tabGroupBorder: {
    height: '4px',
    borderBottom: `1px solid ${GrayscalePalette[400]}`,
    marginBottom: '32px',
  },
  overlayWarning: {
    width: 'inherit',
    maxWidth: '100% !important',
    padding: '24px 18px',
    alignItems: 'center',
  },
  iconStyles: {
    padding: '0 20px 0 10px',
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '750px',
    margin: '20px auto',
    marginBottom: '20px',
    marginTop: '60px',
  },
  badge: {
    maxHeight: '50px',
    backgroundColor: grayPalette.gray60,
    borderColor: grayPalette.gray60,
    minWidth: 'fit-content',
  },
});
