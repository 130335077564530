import { Activity, ActivityType } from '@tempus/t-shared';
import cn from 'classnames';
import { findLast } from 'lodash';
import React, { useState } from 'react';
import { AddSimple } from 'tcl-v3/icons';

import { formatDateForDisplay } from '~/utils/misc';

import useStyles from './ActivityStatus.styles';
import { MatchStatusUpdateActivity } from './MatchStatusUpdateActivity';
import { NewTrialMatchActivity } from './NewTrialMatchActivity';
import { PatientStatusUpdateActivity } from './PatientStatusUpdateActivity';

export const ActivityStatus = ({
  groupedActivities,
  stateId,
  isInternalUser,
}: {
  groupedActivities: GroupedActivities;
  stateId: string;
  isInternalUser: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(groupedActivities.isLast);
  const classes = useStyles();
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {!isOpen ? (
        <div
          className={cn(classes.viewLink, classes.viewMore)}
          onClick={toggleOpen}
          data-testid="update-overlay-activity-view-more">
          <AddSimple height={8} width={8} /> View ({groupedActivities.data.length}) patient updates
        </div>
      ) : (
        <>
          <div className={classes.activityStatusContainer}>
            <ul className={classes.ul}>
              {groupedActivities.data.map((activity) => {
                switch (activity.type) {
                  case ActivityType.MatchStatusUpdates:
                    return (
                      <MatchStatusUpdateActivity
                        activity={activity}
                        isInternalUser={isInternalUser}
                        stateId={stateId}
                      />
                    );
                  case ActivityType.NewTrialMatch:
                    return <NewTrialMatchActivity activity={activity} stateId={stateId} />;
                  case ActivityType.PatientStatusUpdate:
                    return <PatientStatusUpdateActivity activity={activity} />;
                  default:
                    return null;
                }
              })}
            </ul>
            <div className={cn(classes.viewLink)} onClick={toggleOpen} data-testid="update-overlay-activity-view-less">
              - View Less
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const CombinedStatus = 'CombinedStatus';

export interface GroupedActivities {
  type: typeof CombinedStatus;
  data: Activity[];
  isLast: boolean;
}

export const groupActivities = (activities: Activity[]) => {
  const result: (Activity | GroupedActivities)[] = [];

  activities.forEach((activity) => {
    if (activity.type === ActivityType.Note) {
      result.push(activity);
      return;
    }

    const lastElement = result[result.length - 1];
    activity.createdAt = formatDateForDisplay(activity.createdAt, true);
    if (lastElement && lastElement.type === CombinedStatus) {
      (lastElement as GroupedActivities).data.push(activity);
    } else {
      result.push({
        type: CombinedStatus,
        data: [activity],
        isLast: false,
      });
    }
  });

  const lastGroupedActivities = findLast(result, { type: CombinedStatus });

  if (lastGroupedActivities) {
    (lastGroupedActivities as GroupedActivities).isLast = true;
  }

  return result;
};
