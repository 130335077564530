import {
  Activity,
  Institution,
  OperationalFlags,
  ProgramSite,
  TimePatientStatuses,
  TimeProgramSiteStatus,
  Trial,
  Summary,
} from '@tempus/t-shared';
import {
  PATIENT_TRACKER_STATUS_CATEGORIES,
  PATIENT_TRACKER_STATUSES,
} from '@tempus/t-shared/src/constants/patient-tracker';
import { DropdownOption } from 'tcl-v3/models';

import { VisitType } from './constants';

export const GET_PATIENT_TRACKER_V2_RECORDS = 'GET_PATIENT_TRACKER_V2_RECORDS';
export const GET_PATIENT_TRACKER_V2_RECORDS_FAILED = 'GET_PATIENT_TRACKER_V2_RECORDS_FAILED';
export const SET_PATIENT_TRACKER_V2_RECORDS = 'SET_PATIENT_TRACKER_V2_RECORDS';
export const GET_PATIENT_TRACKER_V2_RECORD = 'GET_PATIENT_TRACKER_V2_RECORD';
export const SET_TIME_PATIENT_DATA = 'SET_TIME_PATIENT_DATA';
export const GET_TIME_PATIENT_DATA_FAILED = 'GET_TIME_PATIENT_DATA_FAILED';
export const SET_INSTITUTION_PHYSICIANS = 'SET_INSTITUTION_PHYSICIANS';
export const GET_INSTITUTION_PHYSICIANS_FAILED = 'GET_INSTITUTION_PHYSICIANS_FAILED';
export const CLEAR_PATIENT_TRACKER_V2_RECORD = 'CLEAR_PATIENT_TRACKER_V2_RECORD';
export const GET_PATIENT_TRACKER_V2_RECORDS_CSV = 'GET_PATIENT_TRACKER_V2_RECORDS_CSV';
export const GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED = 'GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED';
export const SET_PATIENT_TRACKER_V2_RECORDS_CSV = 'SET_PATIENT_TRACKER_V2_RECORDS_CSV';
export const SET_PATIENT_TRACKER_V2_STATUS_CATEGORY = 'SET_PATIENT_TRACKER_V2_STATUS_CATEGORY';
export const GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS = 'GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS';
export const SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS = 'SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS';
export const SET_PATIENT_TRACKER_V2_UPDATE_MODAL = 'SET_PATIENT_TRACKER_V2_UPDATE_MODAL';
export const SET_PATIENT_TRACKER_V2_EDIT_OVERLAY = 'SET_PATIENT_TRACKER_V2_EDIT_OVERLAY';
export const SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE = 'SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE';
export const SET_PATIENT_TRACKER_V2_FILTERS = 'SET_PATIENT_TRACKER_V2_FILTERS';
export const SET_PATIENT_TRACKER_V2_PAGINATION = 'SET_PATIENT_TRACKER_V2_PAGINATION';

export enum PatientTrackerStateId {
  PATIENT_TRACKER_V2 = 'patientTrackerV2',
  TRIAL_DOCUMENTS_PAGE_PATIENTS = 'trialDocumentsPagePatients',
}
export interface PatientTrackerFilters {
  [PatientTrackerFilterField.SORT_ORDER]: DropdownOption;
  [PatientTrackerFilterField.TRIAL_NAME]: DropdownOption | null;
  [PatientTrackerFilterField.TRIAL_TYPE]: DropdownOption;
  [PatientTrackerFilterField.TRIAL_STATUS]: DropdownOption[];
  [PatientTrackerFilterField.PHYSICIAN]: string;
  [PatientTrackerFilterField.INDICATIONS]: DropdownOption[];
  [PatientTrackerFilterField.BIOMARKER]: string;
  [PatientTrackerFilterField.PATIENT_NAME]: string;
  [PatientTrackerFilterField.NOTE]: string;
  [PatientTrackerFilterField.PATIENT_ID]: string;
  [PatientTrackerFilterField.RN_ASSIGNMENT]: string;
  [PatientTrackerFilterField.MATCH_STATUS]: DropdownOption[];
}

export enum PatientTrackerFilterField {
  SORT_ORDER = 'sortOrder',
  TRIAL_NAME = 'trialName',
  TRIAL_STATUS = 'trialStatus',
  PHYSICIAN = 'physician',
  INDICATIONS = 'indications',
  BIOMARKER = 'biomarker',
  PATIENT_NAME = 'patientName',
  NOTE = 'note',
  TRIAL_TYPE = 'trialType',
  PATIENT_ID = 'patientId',
  RN_ASSIGNMENT = 'rnAssignment',
  MATCH_STATUS = 'matchStatus',
}

export const PatientTrackerSingleDropdownFilters = [
  PatientTrackerFilterField.SORT_ORDER,
  PatientTrackerFilterField.TRIAL_NAME,
  PatientTrackerFilterField.TRIAL_TYPE,
];

export const PatientTrackerMultiDropdownFilters = [
  PatientTrackerFilterField.INDICATIONS,
  PatientTrackerFilterField.TRIAL_STATUS,
  PatientTrackerFilterField.MATCH_STATUS,
];

export interface PatientTrackerState {
  fetchingRecords: boolean;
  siteId: string;
  statusCategory: PATIENT_TRACKER_STATUS_CATEGORIES;
  patients: TimePatient[];
  patientsMetadata: PatientRecordMetadata;
  categoryCounts: PatientTrackerCategoryCounts;
  updateModal: PatientRecordBrowsingState;
  editOverlay: PatientRecordBrowsingState;
  filters: PatientTrackerFilters;
  exports: PatientRecordExportState;
  patient: PatientRecordState;
  currentInstitutionPhysicians: Physician[] | null;
}

export type PatientTrackerStateMap = Record<string, PatientTrackerState>;

export interface PatientRecordBrowsingState {
  selectedPatientRecord: PatientRecord | null;
  isOpen: boolean;
}

export interface PatientRecordExportState {
  data?: string;
  fileName?: string;
  loading: boolean;
  error?: unknown;
}

export interface PatientRecordState {
  data?: TimePatient;
  loading: boolean;
  error?: unknown;
}

export interface PatientRecordMetadata {
  totalRecords: number;
  numRecords: number;
  skip: number;
  top?: number;
}

export interface PatientRecord {
  dateCreated: string;
  acknowledged: string;
  recordId: number;
  relatedPatientId: number;
  relatedTrialWorkflowId: number;
  patientDob: string;
  patientFullName: string;
  patientFirstName: string;
  patientIdentifiedBy: string;
  patientLastName: string;
  biomarker: string;
  matchDate: string;
  nctId: string;
  cohortId: string;
  cohortName: string;
  trialName: string;
  institutionId: string;
  latestUpdates: string;
  latestUpdatesModifiedAt: string;
  nextVisitDate: string | null;
  visitType: string;
  program: string;
  passedPharmaOpsReview: string;
  pharmaOpsReviewCompletedBy: string;
  pharmaOpsReviewCompletedDate: string;
  tAppNotes: string;
  timePatientId: string;
  timeSite: string;
  trial: Pick<Trial, 'id' | 'nctId' | 'shortName' | 'arms'> & { programSites: ProgramSite[] };
  patientMatchStatus: string;
}

export type DeprecatedTimePatient = Pick<
  PatientRecord,
  | 'recordId'
  | 'institutionId'
  | 'patientFirstName'
  | 'patientLastName'
  | 'patientDob'
  | 'patientIdentifiedBy'
  | 'program'
> & { timePatientId: string; timeSite: string };

export interface PatientTrackerCategoryCounts {
  [PATIENT_TRACKER_STATUS_CATEGORIES.NEW]: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.PRIORITY]: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.MONITORING]: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED]: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE]: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.SCREENING_QUEUE]: number | null;
}

export interface PatientRecordsFilters extends TableFilters {
  top?: number;
  skip?: number;
}
export interface PatientRecordsQuery {
  includeFilters?: PatientRecordsFilters;
  excludeFilters?: PatientRecordsFilters;
  sortBy?: string[];
}

export interface PatientTrackingDetailArms {
  id: string;
  name: string;
  operationalFlag: OperationalFlags;
  armSummary: {
    summary: Summary;
    updatedAt: string;
  };
  siteArms: {
    armId: string;
    institutionId: string;
    status: TimeProgramSiteStatus;
  }[];
}

type TableFilters = Partial<Record<keyof PatientRecord, string | string[] | number | number[]>>;

export interface PatientTrackingDetails {
  matchingFilters: boolean;
  id: string;
  recordId: number;
  patientId: string;
  patientLinkageId: string;
  reasonNotAMatch: string | null;
  reasonNotAMatchDetails: string | null;
  tempusMdNotes: string;
  portalToTempusNextVisit: string;
  portalToTempusNextVisitType: string;
  passedRnReview: string;
  trial: Pick<Trial, 'id' | 'nctId' | 'shortName' | 'title'> & { type: 'Observational' | 'Interventional' };
  arm?: PatientTrackingDetailArms;
  tempusToPortalLatestPortalPatientUpdate: string;
  tempusToPortalLatestPortalUpdateModifiedDate: string;
  programSite?: Pick<ProgramSite, 'status' | 'programType'>;
  linkedTreatingMd: string;
  mutations?: string;
  institutionId: string;
  mrn?: string;
  patientCancerType: string;
  status: PATIENT_TRACKER_STATUSES;
  statusDate: string | null;
  timePatientId: string;
  rescreen: boolean;
  noCredit: boolean;
  dateRnClearedForNotification: string | null;
  dateNotificationSentToSite: string | null;
  patientConsentedDate: string | null;
  dateConsentRecognizedByTempus: string | null;
  firstTreatmentDate?: string | null;
  timeRnReviewCompletedDate?: string | null;
  screenedBy?: string | null;
  tAppNotes?: string | null;
  internalRnToScreeningRnNote?: string | null;
}

export interface PatientTrackerPatient {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  timePatient: TimePatient;
  relatedPatientTrackingDetails: PatientTrackingDetails[];
}

export interface TimePatient {
  id: string;
  patientLinkageId: string;
  responseRequired: boolean;
  status: TimePatientStatuses;
  timePatientVisits: {
    visitDate: string;
    visitType: VisitType;
  }[];
  institution: Institution;
  patient: Patient;
  patientTrackingDetails: (PatientTrackingDetails & { editing: boolean })[];
  activities: Activity[];
  billingId: string;
  physician?: Physician;
}

export interface Physician {
  id: string;
  name: string;
}

export interface PatientTrackingDetailsWithPatientMeta extends PatientTrackingDetails {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
}
export interface Patient {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  linkageId: string;
  patientTrackingDetails: PatientTrackingDetails[];
}

export interface GetPatientTrackerRecordsOpts {
  includeNonMatchingRecords?: boolean;
  includeAllCategories?: boolean;
  customFilterList?: PatientTrackerFilterField[];
}

export interface GetPatientTrackerRecords {
  type: typeof GET_PATIENT_TRACKER_V2_RECORDS;
  id: string;
  siteId: string;
  opts?: GetPatientTrackerRecordsOpts;
}

interface SetPatientTrackerRecords {
  type: typeof SET_PATIENT_TRACKER_V2_RECORDS;
  id: string;
  patients: TimePatient[];
}

export interface GetPatientTrackerRecordsFailed {
  type: typeof GET_PATIENT_TRACKER_V2_RECORDS_FAILED;
  id: string;
  error: unknown;
}

export interface GetPatientTrackerRecord {
  type: typeof GET_PATIENT_TRACKER_V2_RECORD;
  id: string;
  siteId: string;
  timePatientId: string;
}

export interface SetTimePatientData {
  type: typeof SET_TIME_PATIENT_DATA;
  id: string;
  patient: TimePatient;
}

export interface GetTimePatientDataFailed {
  type: typeof GET_TIME_PATIENT_DATA_FAILED;
  id: string;
  error: unknown;
}

export interface SetInstitutionPhysicians {
  type: typeof SET_INSTITUTION_PHYSICIANS;
  id: string;
  physicians: Physician[];
}

export interface GetInstitutionPhysiciansFailed {
  type: typeof GET_INSTITUTION_PHYSICIANS_FAILED;
  id: string;
  error: unknown;
}

export interface ClearPatientTrackerRecord {
  type: typeof CLEAR_PATIENT_TRACKER_V2_RECORD;
  id: string;
}

interface SetPatientTrackerStatusCategory {
  type: typeof SET_PATIENT_TRACKER_V2_STATUS_CATEGORY;
  id: string;
  statusCategory: PATIENT_TRACKER_STATUS_CATEGORIES;
}

export interface GetPatientCategoryCounts {
  type: typeof GET_PATIENT_TRACKER_V2_CATEGORY_COUNTS;
  id: string;
  siteId: string;
  currentStatusCategory?: PATIENT_TRACKER_STATUS_CATEGORIES;
  opts?: GetPatientTrackerRecordsOpts;
}

interface SetPatientCategoryCounts {
  type: typeof SET_PATIENT_TRACKER_V2_CATEGORY_COUNTS;
  id: string;
  categoryCounts: PatientTrackerCategoryCounts;
}

interface SetPatientTrackerUpdateModal {
  type: typeof SET_PATIENT_TRACKER_V2_UPDATE_MODAL;
  id: string;
  selectedPatientRecord?: PatientRecord | null;
  isOpen?: boolean;
}

interface SetPatientTrackerEditOverlay {
  type: typeof SET_PATIENT_TRACKER_V2_EDIT_OVERLAY;
  id: string;
  selectedPatientRecord?: PatientRecord | null;
  isOpen?: boolean;
}

// TODO: Temporarily setting all parameters except for type for this action as optional to silent store error
// due to using union action types but no action is actually of type Action<any>.
export interface ShowPatientTrackerErrorMessage {
  type: typeof SHOW_PATIENT_TRACKER_V2_ERROR_MESSAGE;
  id?: string;
  error?: unknown;
  defaultErrorMessage?: string;
}

interface SetPatientTrackerFilters {
  type: typeof SET_PATIENT_TRACKER_V2_FILTERS;
  id: string;
  filters: Partial<PatientTrackerFilters>;
}

interface SetPatientTrackerPagination {
  type: typeof SET_PATIENT_TRACKER_V2_PAGINATION;
  id: string;
  numRecords: number;
  skip: number;
  totalRecords: number;
}

export interface GetPatientTrackerRecordsCsv {
  type: typeof GET_PATIENT_TRACKER_V2_RECORDS_CSV;
  id: string;
  siteId: string;
  opts?: GetPatientTrackerRecordsOpts;
}

interface GetPatientTrackerRecordsCsvFailed {
  type: typeof GET_PATIENT_TRACKER_V2_RECORDS_CSV_FAILED;
  id: string;
  error: unknown;
}

interface SetPatientTrackerRecordsCsv {
  type: typeof SET_PATIENT_TRACKER_V2_RECORDS_CSV;
  id: string;
  data: string;
  fileName: string;
}

export type PatientTrackerActionTypes =
  | GetPatientTrackerRecords
  | GetPatientTrackerRecordsFailed
  | SetPatientTrackerRecords
  | GetPatientTrackerRecord
  | SetTimePatientData
  | GetTimePatientDataFailed
  | SetInstitutionPhysicians
  | GetInstitutionPhysiciansFailed
  | ClearPatientTrackerRecord
  | SetPatientTrackerStatusCategory
  | GetPatientCategoryCounts
  | SetPatientCategoryCounts
  | SetPatientTrackerUpdateModal
  | SetPatientTrackerEditOverlay
  | ShowPatientTrackerErrorMessage
  | SetPatientTrackerFilters
  | SetPatientTrackerPagination
  | GetPatientTrackerRecordsCsv
  | GetPatientTrackerRecordsCsvFailed
  | SetPatientTrackerRecordsCsv;
